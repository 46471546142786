import React, { useEffect, useState, ReactNode  } from 'react';
import './pageStyles/LandingPage.css';
import useAnalyticsEventTracker from '../components/_common/useAnalyticsEventTracker';
import DonateSection from '../components/landingPage/DonateSection';
import MainHero from '../components/landingPage/MainHero';
import Header from '../components/landingPage/LandingHeader';
import {AboutUs} from './AboutUs';
import '../App.css';
import Canvas from '../components/landingPage/Canvas';
import Product from '../components/landingPage/Product';
import LazyShow from '../components/landingPage/LazyShow';
import MainHeroImage from '../components/landingPage/MainHeroImage';
import Features from '../components/landingPage/Features';
import { useLocation } from 'react-router-dom';
import { Footer } from '../components/landingPage/Footer';

export function LandingPageFin() {
  const gaEventTracker = useAnalyticsEventTracker('landingPage');
  gaEventTracker('enterNgLanding');
  const [shouldRender, setShouldRender] = useState(false);

  const location = useLocation();

  useEffect(() => {
      const hasRefreshed = sessionStorage.getItem('hasRefreshed');
      if (location.pathname === '/' && (!hasRefreshed || hasRefreshed === 'false')) {
          sessionStorage.setItem('hasRefreshed', 'true');
          window.location.reload();
      } else {
          setShouldRender(true); // Only render the children after the refresh check
      }
  }, [location]);

  if (!shouldRender) {
    return null; // Prevent initial render until refresh logic is handled
  } else {
    return (
      <div >
        <div className={`bg-background grid gap-y-16 overflow-hidden`}>
          <div className={`relative bg-background`}>
            <div className="max-w-7xl mx-auto">
              <div
                className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
              >
                <Header />
                <MainHero />
              </div>
            </div>
            <MainHeroImage />
          </div>
          <Canvas />
          <LazyShow>
            <>
              <Product />
              <Canvas />
            </>
          </LazyShow>
          <LazyShow>
            <>
              <Features />
              <Canvas />
            </>
          </LazyShow>
          <LazyShow>
              <AboutUs />
          </LazyShow>

        </div>
        <Footer />
      </div>
    );
  }
}
